import { ButtonProps, Create, Drawer, DrawerProps, Form, FormProps, Grid, Radio, Select, Typography, useSelect } from "@pankod/refine-antd";
import { useOne } from "@pankod/refine-core";
import { IEngagement, ILookup, IProgram } from "interfaces";
import { DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, RESOURCE_PATH } from "scripts/site";

type CreateAssessmentProps = {
  drawerProps: DrawerProps;
  mode: "Engagement" | "Program" | "None";
  formProps: FormProps;
  saveButtonProps: ButtonProps;
  engagementId: string;
  programList: IProgram[]
};

export const CreateEnggProgram: React.FC<CreateAssessmentProps> = ({
  drawerProps,
  formProps,
  saveButtonProps,
  engagementId,
  programList,
  mode = "None"
}) => {

  const breakpoint = Grid.useBreakpoint();
  const models: any = [];
  programList.forEach(md => {
    models.push(<Radio.Button key={md.id} value={md.id}>{md.name}</Radio.Button>);
  });

  const { data: fullEngagment } = useOne<IEngagement>({
    dataProviderName: DATAPROVIDER_READ,
    resource: RESOURCE_PATH.ENGAGEMENT,
    id: engagementId,
    queryOptions: {
      enabled: mode === "Engagement" && !!engagementId
    },
  });


  const { selectProps: userProps } = useSelect<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.PEOPLE,
    optionLabel: "nameEmail",
    optionValue: "id",
    filters: [
      {
        field: "id",
        operator: "eq",
        value: fullEngagment?.data.salesExecutives.concat(fullEngagment?.data.deliveryExecutives).concat(fullEngagment?.data.otherAuthorizedUsers).join(",")  // this is the value of the field to be filtered
      }
    ],
    sort: [
      {
        field: "lastAccessedAt",
        order: "desc"
      },
    ],
    fetchSize: 50,
    queryOptions: {
      enabled: !!fullEngagment?.data
    }
  });

  return (
    <Drawer getContainer={false}
      {...drawerProps}
      width={breakpoint.sm ? "700px" : "100%"}
    >
      <Create saveButtonProps={saveButtonProps}
        headerProps={{
          breadcrumb: {},
          backIcon: false,
          title: "Add Program",
          tags: <></>
        }}
      >
        <Form {...formProps}
          initialValues={{ engagementId: engagementId }}
          layout="vertical">

          <Form.Item
            hidden
            label="Engagement"
            name="engagementId"
          >
            <Typography.Text>{engagementId}</Typography.Text>
          </Form.Item>
          <Form.Item
            label="Program"
            name="programId"
            rules={[
              {
                required: true,
                whitespace: true
              },
            ]}
          >
            <Radio.Group
              optionType="button"
              buttonStyle="solid"
            >
              {models}
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Program SPOC"
            name="programSpoc"
            tooltip="To look up any missing name type the name of the person and then select"
            rules={[
              {
                required: true,
                whitespace: true
              },
            ]}
          >
            <Select
              allowClear
              placeholder="Program SPOC"
              {...userProps}
            />
          </Form.Item>
        </Form>
      </Create>
    </Drawer >
  );
};

import { Alert, Create, Form, Input, SaveButton, Select, useForm, useSelect } from "@pankod/refine-antd";
import { IResourceComponentsProps, useCan, useGetIdentity, useOne } from "@pankod/refine-core";
import { CancelButton } from "components/utils/cancelButton";
import { ILookup, IUser } from "interfaces";
import { IScenario, IScenarioMetadata } from "interfaces/article";
import { DATAPROVIDER_CREATE, DATAPROVIDER_LOOKUP, RESOURCE_PATH, STALE_DURATION } from "scripts/site";

export const ScenarioCreate: React.FC<IResourceComponentsProps> = () => {
  const { data } = useGetIdentity();
  const user = data as IUser;

  const { formProps, saveButtonProps } = useForm<IScenario>({
    dataProviderName: DATAPROVIDER_CREATE
  });

  const { selectProps: usersList } = useSelect<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.PEOPLE,
    optionLabel: "nameEmail",
    optionValue: "id",
    sort: [{
      field: "lastAccessedAt",
      order: "desc"
    }]
  });

  const canListProgram = useCan({
    resource: RESOURCE_PATH.PROGRAM,
    action: "get"
  });


  const { selectProps: programList } = useSelect<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.PROGRAM,
    optionLabel: "name",
    optionValue: "id",
    sort: [{
      field: "name",
      order: "asc"
    }],
    queryOptions: {
      enabled: canListProgram?.data?.can === true
    }
  });


  const { selectProps: serviceLineList } = useSelect<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.SERVICELINE,
    optionLabel: "name",
    optionValue: "id",
    sort: [{
      field: "name",
      order: "asc"
    }]
  });

  const { data: metadata } = useOne<IScenarioMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.SCENARIO,
    queryOptions: {
      enabled: true,
      staleTime: STALE_DURATION
    }
  });

  const metaConfig = metadata?.data.config;

  return (
    <Create
      title="Author a Scenario"
      footerButtons={<>
        <SaveButton {...saveButtonProps}>Save draft</SaveButton>
        <CancelButton />
      </>}

    >
      <Form {...formProps} layout="vertical" autoComplete="off"
        initialValues={{
          authorName: user?.id ? [user?.id] : []
        }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              whitespace: true,
              min: 5,
              max: 100
            },
          ]}
        >
          <Input placeholder="Name for the scenario" />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
              whitespace: true,
              min: 15,
              max: 2000
            },
          ]}
        >
          <Input.TextArea rows={2} placeholder="Short abstract outlining the scenario" />
        </Form.Item>
        {canListProgram.data?.can ?
          <Form.Item
            label="Program"
            name="programId"
            rules={[
              {
                required: true,
                whitespace: true,
                type: "string",
              },
            ]}
          >
            <Select placeholder="Search Program" {...programList} allowClear />
          </Form.Item>
          : <Alert
            message="Program access denied"
            description={"You don't have access to Program data. Please contact your Engagement owners to map a Program with this article."}
            type="warning"
            showIcon
            style={{ marginBottom: 15 }}
          />
        }

        <Form.Item
          label="Service Lines"
          name="serviceLineIds"
          rules={[
            {
              required: true,
              whitespace: true,
              type: "array",
              min: 1,
            },
          ]}
        >
          <Select placeholder="Search Service Lines" mode="multiple" {...serviceLineList} allowClear />
        </Form.Item>

        <Form.Item
          label="Value Dimensions"
          name="valueDimensions"
          rules={[
            {
              required: true,
              type: "array",
              min: 1,
            },
          ]}
        >
          <Select
            placeholder="Search Value Dimension. You can add as many as you want."
            mode="multiple"
            options={(metaConfig?.valueDimensions || []).map((m, i) => ({ value: m, label: m }))}
            allowClear
          >
          </Select>
        </Form.Item>

        <Form.Item
          label="Fulfilled via Technology"
          name="technology"
          rules={[
            {
              required: true,
              type: "array",
              min: 1,
            },
          ]}
        >
          <Select placeholder="Technology"
            mode="multiple"
            options={(metaConfig?.technologies || []).map((m, i) => ({ value: m, label: m }))}
            allowClear>
          </Select>
        </Form.Item>

        <Form.Item
          label="Author(s)"
          name="authorName"
          rules={[
            {
              required: true,
              whitespace: true,
              type: "array",
              min: 1,
              max: 5,
              message: "Minimum 1 and maximum of 5 authors"
            },
          ]}
        >
          <Select placeholder="Search Name or Email" mode="multiple" {...usersList} allowClear />
        </Form.Item>
      </Form>
    </Create >
  );
};
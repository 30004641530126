import { arrayExtensions, stringExtensions } from "@architecture-innovation-transformation/lib-common";
import {
  Button, Checkbox, Divider, Edit, Form,
  Icons, Input, InputNumber,
  Radio, RcFile, SaveButton, Select, Steps, Tag, Typography, Upload,
  getValueFromEvent,
  message,
  useFileUploadState, useStepsForm
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl, useOne } from "@pankod/refine-core";
import { CancelButton } from "components/utils/cancelButton";

import { IListData, IUser, IUserMetadata } from "interfaces";
import { MediaType, UserType } from "interfaces/enum";
import { useEffect, useState } from "react";
import { DATAPROVIDER_LOOKUP, DATAPROVIDER_UPDATE, DATAPROVIDER_UPLOAD, MY_PROFILE, RESOURCE_PATH, SELECTION_LISTS, STALE_DURATION, removeLocalSessionStorage, validateAddedTags } from "scripts/site";

export const ProfileEdit: React.FC<IResourceComponentsProps> = () => {
  const { current, formProps, stepsProps, saveButtonProps, queryResult, form } = useStepsForm<IUser>({
    dataProviderName: DATAPROVIDER_UPDATE,
    onMutationSuccess: () => { removeLocalSessionStorage(MY_PROFILE) }
  });

  const userType = queryResult?.data?.data?.userType;
  const blockedTagsMessage = "Tag(s) added is/are in blocked list.";

  const [contextualMaster, setContextualMaster] = useState(false);
  const [imageList, setImageList] = useState([{ label: "No Image Uploaded", value: "no-image" }]);
  const { Step } = Steps;
  const apiUrl = useApiUrl(DATAPROVIDER_UPLOAD);
  const { isLoading: isUploading, onChange: onUploadChange } = useFileUploadState();

  useEffect(() => {

    let usrImages = queryResult?.data?.data?.userImages || [];
    setImageList(usrImages?.map(ut => ({ label: stringExtensions.capitalize(ut.name), value: ut.uid })) || []);
    setContextualMaster(queryResult?.data?.data?.employee?.contextualMaster || false);

  }, [queryResult?.isFetching, queryResult?.data?.data?.userImages, queryResult?.data?.data?.employee?.contextualMaster])

  const { data: hobbiesList } = useOne<IListData>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.LIST,
    id: SELECTION_LISTS.HOBBIES
  });

  const { data: technicalExpertiseList } = useOne<IListData>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.LIST,
    id: SELECTION_LISTS.TECHNICAL_EXPERTISE
  });

  const { data: functionalExpertiseList } = useOne<IListData>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.LIST,
    id: SELECTION_LISTS.FUNCTIONAL_EXPERTISE
  });

  const { data: entityMetadata } = useOne<IUserMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.USERS,
    queryOptions: {
      enabled: true,
      staleTime: STALE_DURATION
    }
  });

  const metaConfig = entityMetadata?.data?.config;

  const setNotificationMail = (mailType: string) => {
    if (mailType === "login") {
      form.setFieldValue(["preferences", "notificationEmail"], form.getFieldValue("id"));
    } else if (mailType === "tcs") {
      form.setFieldValue(["preferences", "notificationEmail"], form.getFieldValue(["employee", "tcsEmail"]));
    } else {
      form.setFieldValue(["preferences", "notificationEmail"], "");
    }
  }

  const validateTCSEmailFormt = (mailAddress: string) => {
    let isValid = false;
    if (mailAddress && mailAddress.includes('@')) {
      let localPart = mailAddress.split("@")[0];
      if (isNaN(+localPart)) {
        isValid = true;
      }
    }
    return isValid;
  }

  const formList = [
    <>
      <Form.Item
        label="Name"
        name="name"
        rules={[
          {
            required: true,
            whitespace: true,
            max: 100
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Country"
        name="countryId"
        valuePropName="children"
        tooltip="Non editable field"
      >
        <Typography.Text copyable></Typography.Text>
      </Form.Item>
      <Form.Item
        label="Location"
        name="locationId"
        valuePropName="children"
        tooltip="Non editable field"
      >
        <Typography.Text copyable></Typography.Text>
      </Form.Item>
      <Form.Item
        label="Email"
        name="id"
        valuePropName="children"
        tooltip="Non editable field"
      >
        <Typography.Text copyable></Typography.Text>
      </Form.Item>
      <Form.Item
        label="User Type"
        name="userType"
        tooltip="Non editable field. Please reach out to PMO Team to update this field."
      >
        <Typography.Text>{stringExtensions.capitalize(userType ?? "")}</Typography.Text>
      </Form.Item>
    </>,
    <>
      <Form.Item
        label="Headline"
        name="headline"
        rules={[
          {
            required: false,
            whitespace: true,
            max: 50
          }
        ]}
      >
        <Input placeholder="Mention your role" />
      </Form.Item>
      <Form.Item
        label="About Me"
        name="aboutMe"
        rules={[
          {
            required: false,
            whitespace: true,
            max: 250
          }
        ]}
      >
        <Input.TextArea rows={3} placeholder="Short abstract introducing yourself to your colleagues" />
      </Form.Item>
      <Form.Item
        label="LinkedIn Profile URL"
        name="linkedinUrl"
        rules={[
          {
            required: false,
            type: "url"
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Hobbies"
        name="hobbies"
        rules={[
          {
            required: false,
            type: "array",
            validator(_rule, values) {
              let validationResult = validateAddedTags(values, hobbiesList?.data.blocked || []);
              if (arrayExtensions.validateArray(validationResult)) {
                return Promise.reject(new Error(blockedTagsMessage + ' - ' + validationResult.join(", ")));
              } else {
                return Promise.resolve();
              }

            },
          }
        ]}
      >
        <Select mode="tags"
          placeholder="Type your hobby and press enter. You can add as many as you want."
          tokenSeparators={[',']}
        >
          {hobbiesList?.data.data.map((m, i) => (
            <Select.Option value={m} key={i}>{m}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Area of Technical Expertise"
        name="technicalExpertiseAreas"
        rules={[
          {
            required: false,
            type: "array",
            validator(_rule, values) {
              let validationResult = validateAddedTags(values, technicalExpertiseList?.data.blocked || []);
              if (arrayExtensions.validateArray(validationResult)) {
                return Promise.reject(new Error(blockedTagsMessage + ' - ' + validationResult.join(", ")));
              } else {
                return Promise.resolve();
              }

            },
          }
        ]}
      >
        <Select mode="tags"
          placeholder="Type area of expertise and press enter. You can add as many as you want."
          tokenSeparators={[',']}
        >
          {technicalExpertiseList?.data.data.map((m, i) => (
            <Select.Option value={m} key={i}>{m}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Area of Functional Expertise"
        name="functionalExpertiseAreas"
        rules={[
          {
            required: false,
            type: "array",
            validator(_rule, values) {
              let validationResult = validateAddedTags(values, functionalExpertiseList?.data.blocked || []);
              if (arrayExtensions.validateArray(validationResult)) {
                return Promise.reject(new Error(blockedTagsMessage + ' - ' + validationResult.join(", ")));
              } else {
                return Promise.resolve();
              }
            },
          }
        ]}
      >
        <Select
          mode="tags"
          placeholder="Type area of expertise and press enter. You can add as many as you want."
          tokenSeparators={[',']}
        >
          {functionalExpertiseList?.data.data.map((m, i) => (
            <Select.Option value={m} key={i}>{m}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Divider orientation="left">Media Section</Divider>
      <Tag color="yellow" style={{ marginBottom: 10, whiteSpace: 'normal' }}><b>Note:</b> Uploading an image is optional. By uploading an image, you agree to abide by the Organization's Media usage Policy and Helium's terms of use.</Tag>
      <Form.Item
        label="Upload images to be used as profile images"
        name="userImages"
        valuePropName="fileList"
        getValueFromEvent={getValueFromEvent}
        tooltip="Upload up to 3 images to choose as profile images from."
        rules={[
          {
            required: false,
            validator(_, value) {
              if (value) {
                setImageList(value.map((ut: any) => ({ label: stringExtensions.capitalize(ut.name), value: ut.uid })))
              }
              return Promise.resolve();
            }
          }
        ]}
      >
        <Upload
          name="file"
          accept="image/*"
          action={`${apiUrl}/${RESOURCE_PATH.PROFILE}/${MediaType.Image}`}
          listType="picture"
          onChange={onUploadChange}
          maxCount={3}
          multiple
          beforeUpload={(file: RcFile) => {
            const isImageFile = file.type.search('image') === 0;
            if (!isImageFile) {
              message.error(`${file.name} is not an image file!`);
            }
            const isLt2M = (file.size / 1024 / 1024) < 2;
            if (!isLt2M) {
              message.error(`${file.name} is greater than 2MB!`);
            }
            return isImageFile && isLt2M;
          }}
        >
          <Button icon={<Icons.UploadOutlined />}>Upload (Max: 3)</Button>
        </Upload>
      </Form.Item>
      <Form.Item
        label="Profile Image"
        name="userProfileImage"
        rules={[
          {
            required: false,
            whitespace: true
          }
        ]}
      >
        <Select placeholder="Select your profile image from list of uploaded images" >
          {imageList.map((m, i) => (
            <Select.Option value={m.value} key={i}>{m.label}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Metaverse Avatar"
        name="userMetaverseAvatar"
        rules={[
          {
            required: false,
            whitespace: true
          }
        ]}
      >
        <Select placeholder="Select your Metaverse avatar from list of uploaded images">
          {imageList.map((m, i) => (
            <Select.Option value={m.value} key={i}>{m.label}</Select.Option>
          ))}
        </Select>
      </Form.Item>
    </>
  ];

  if (userType && userType === UserType.Employee) {
    formList.push(<>
      <Divider orientation="left">Employee basics</Divider>
      <Form.Item
        label="TCS Employee ID"
        name={["employee", "employeeId"]}
        rules={[
          {
            required: true,
            whitespace: true,
            type: "number",
          },
        ]}
      >
        <InputNumber maxLength={7} controls={false} style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        label="TCS Card number"
        name={["employee", "cardNumber"]}
        rules={[
          {
            required: true,
            whitespace: true,
            type: "string",
          },
        ]}
      >
        <Input maxLength={10} />
      </Form.Item>
      <Form.Item
        label="TCS Email"
        name={["employee", "tcsEmail"]}
        tooltip="Provide you TCS Email ID in emailid@tcs.com format. Please note email ID in format employeeid@tcs.com does not work from external systems."
        rules={[
          {
            type: "email",
            whitespace: true,
            min: 5,
          },
          {
            validator: async (_, value: string) => {
              if (!value) {
                form.setFieldsValue({ "employee": { "tcsEmail": null } })
                return;
              }

              if ((metaConfig?.tcsDomains ?? []).some(e => value.endsWith(e)) && validateTCSEmailFormt(value)) {
                return Promise.resolve();
              }
              else {
                return Promise.reject(
                  new Error("'Email' must belong to valid TCS domains - " + (metaConfig?.tcsDomains ?? []).join(", ") + " and should NOT be in format of employeeid@tcs.com"),
                );
              }
            }
          }
        ]}
      >
        <Input placeholder="Email ID in sample.user@tcs.com. Please note employeeid@tcs.com is not valid from external systems." />
      </Form.Item>
      <Form.Item
        label="Phone number"
        name={["employee", "phoneNumber"]}
        rules={[
          {
            required: true,
            whitespace: true,
            type: "string"
          },
        ]}
      >
        <InputNumber maxLength={12} controls={false} stringMode style={{ width: '100%' }} />
      </Form.Item>
      <Divider orientation="left">Office basics</Divider>
      <Form.Item
        label="Working From"
        name={["employee", "workingFrom"]}
        rules={[
          {
            required: true,
            whitespace: true,
          },
        ]}
      >
        <Radio.Group buttonStyle="solid">
          <Radio.Button value="Home">Home</Radio.Button>
          <Radio.Button value="TCS Office">TCS Office</Radio.Button>
          <Radio.Button value="Client Office">Client Office</Radio.Button>
          <Radio.Button value="Hybrid">Hybrid</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label="Building Access"
        name={["employee", "buildingAccess"]}
        rules={[
          {
            required: true,
            whitespace: true,
          },
        ]}
      >
        <Select>
          <Select.Option value="Able to access TCS and ODC">Able to access TCS and ODC</Select.Option>
          <Select.Option value="No access to TCS and ODC">No access to TCS and ODC</Select.Option>
          <Select.Option value="No access to TCS office">No access to TCS office</Select.Option>
          <Select.Option value="No access to ODC">No access to ODC</Select.Option>
          <Select.Option value="No Identity card, picture uploaded">No Identity card, picture uploaded</Select.Option>
          <Select.Option value="Do not know">Do not know</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Desk"
        name={["employee", "desk"]}
        rules={[
          {
            whitespace: true,
            type: "string",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Divider orientation="left">Transport basics</Divider>
      <Form.Item
        label="Vehicle Make/Model"
        name={["employee", "vehicleMakeModel"]}
        rules={[
          {
            type: "string",
            whitespace: true
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Vehicle Color"
        name={["employee", "vehicleColor"]}
        rules={[
          {
            type: "string",
            whitespace: true
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Vehicle License Plate Number"
        name={["employee", "vehicleLicense"]}
        rules={[
          {
            type: "string",
            whitespace: true
          }
        ]}
      >
        <Input />
      </Form.Item>
    </>);

    formList.push(<>
      <Form.Item
        label="Are you a Contextual Master?"
        name={["employee", "contextualMaster"]}
        valuePropName="checked"
        rules={[
          {
            required: false,
            type: "boolean"
          }
        ]}
      >
        <Checkbox checked={contextualMaster} onChange={() => setContextualMaster(!contextualMaster)} />
      </Form.Item>
      <Form.Item
        label="Contextual Master Story Link"
        name={["employee", "contextualMasterStory"]}
        dependencies={["employee", "contextualMaster"]}
        hidden={!contextualMaster}
        rules={[
          {
            required: contextualMaster,
            type: "url"
          },
        ]}
      >
        <Input placeholder="Please provide published contextual master Knome link" />
      </Form.Item>
      <Form.Item
        label="Technical Certifications"
        name={["employee", "technicalCertificationList"]}
        rules={[
          {
            required: false,
            type: "array",
          }
        ]}
      >
        <Select mode="tags" placeholder="Type your certification and press enter. You can add as many as you have." tokenSeparators={[',']}>
        </Select>
      </Form.Item>
      <Form.Item
        label="Functional Certifications"
        name={["employee", "functionalCertificationList"]}
        rules={[
          {
            required: false,
            type: "array",
          }
        ]}
      >
        <Select mode="tags" placeholder="Type your certification and press enter. You can add as many as you have." tokenSeparators={[',']}>
        </Select>
      </Form.Item>
    </>);
  }
  formList.push(<>
    <Form.Item
      label="App theme"
      name={["preferences", "theme"]}
      rules={[
        {
          required: false,
          type: "string"
        }
      ]}
    >
      <Radio.Group optionType="button" buttonStyle="solid">
        <Radio value='light'>Light</Radio>
        <Radio value='dark'>Dark</Radio>
      </Radio.Group>
    </Form.Item>
    <Form.Item
      label="Notify me on"
      name={["preferences", "notificationEmailType"]}
      rules={[
        {
          required: true,
          whitespace: true
        }
      ]}
    >
      <Radio.Group
        optionType="button"
        buttonStyle="solid"
        onChange={(ev) => { setNotificationMail(ev.target.value) }}>
        <Radio.Button key='login' value='login'>Login Mail ID</Radio.Button>
        {userType && userType === "employee" && form.getFieldValue(["employee", "tcsEmail"]) &&
          <Radio.Button key='tcs' value='tcs'>TCS Mail ID</Radio.Button>
        }
        <Radio.Button key='custom' value="custom">Specific work email</Radio.Button>
      </Radio.Group>
    </Form.Item>
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, currentValues) => prevValues.preferences.notificationEmailType !== currentValues.preferences.notificationEmailType}
    >
      {({ getFieldValue }) =>
        getFieldValue(["preferences", "notificationEmailType"]) === "custom" ? (
          <>
            <Form.Item
              label="Work Email to be notified on"
              name={["preferences", "notificationEmail"]}
              tooltip="Kindly provide the work email on which you prefer to be notified"
              rules={[
                {
                  required: true,
                  type: "email",
                  whitespace: true,
                  min: 5
                },
                {
                  validator: async (_, value: string) => {
                    if ((metaConfig?.emailDomains ?? []).some(e => value.endsWith(e))) {
                      return Promise.resolve();
                    }
                    else {
                      return Promise.reject(
                        new Error("'Email' must belong to any of these domains - " + (metaConfig?.emailDomains ?? []).join(", ")),
                      );
                    }
                  }
                }
              ]}
            >
              <Input />
            </Form.Item>
          </>
        ) :
          <Form.Item
            label="Notification Email"
            name={["preferences", "notificationEmail"]}
            tooltip="Email on which you will be notified"
          >
            <Input disabled />
          </Form.Item>
      }
    </Form.Item>
  </>
  )

  return (
    <Edit
      isLoading={queryResult?.isFetching}
      headerProps={{
        extra: <></>
      }}
      footerButtons={() => (
        <>
          <SaveButton {...saveButtonProps} disabled={isUploading} />
          <CancelButton />
        </>
      )}
    >
      <Divider dashed />
      <Steps responsive {...stepsProps} >
        <Step
          key="basic"
          title="Basic Details"
        />
        <Step
          key="aboutme"
          title="About Me"
        />
        {userType === UserType.Employee &&
          <>
            <Step
              key="employee"
              title="Employee Details"
            />
            <Step
              key="randr"
              title="Rewards and Recognition"
            />
          </>
        }
        <Step
          key="preferences"
          title="Preferences"
        />
      </Steps>
      <Divider dashed />
      <Form
        {...formProps}
        autoComplete="off"
        layout="vertical"
      >
        {formList[current]}
      </Form>
    </Edit>
  );
};

import { Button, CreateButton, Descriptions, Drawer, ExportButton, Icons, List, Table, Typography, useTable } from "@pankod/refine-antd";
import { CanAccess, CrudFilters, CrudSorting, HttpError, IResourceComponentsProps, useExport, useGetIdentity, useOne, useUpdate } from "@pankod/refine-core";
import type { ColumnsType } from 'antd/es/table';
import { RenderMDFile } from "components/common";
import { RenderWorkflowModal } from "components/common/workflowModal";
import { mapFiltersToFormFields } from "components/resourcing/mapFilters";
import { RequestFilter } from "components/resourcing/requestFilter";
import { StatsTile } from "components/statistics/statsTile";
import { generateCrudFilters, generatePageSize, generateSortOrder } from "components/utils/generateCrudFilters";
import { GetListTableColumns, mergePreferences, preferencesData } from "components/utils/getListTableColumns";
import { ListColumnPicker } from "components/utils/listColumnPicker";
import { IBaseProps, IEntityListColumns, IState, IUser, IUserPreferences } from "interfaces";
import { IResourcingRequest, IResourcingRequestFilterVariables, IResourcingRequestMetadata } from "interfaces/resourcing";
import { useEffect, useState } from "react";
import { DATAPROVIDER_COUNT, DATAPROVIDER_CREATE, DATAPROVIDER_EXPORT, DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, DATAPROVIDER_UPDATE, MY_PROFILE, RESOURCE_PATH, STALE_DURATION, removeLocalSessionStorage } from "scripts/site";
import { RequestExpand } from "./requestExpand";

export const ResourcingRequestList: React.FC<IResourceComponentsProps> = () => {
    const { data: userData } = useGetIdentity();
    const userObj = userData as IUser;

    const resourcingFilter: CrudFilters = userObj?.entityRoleIds.includes("resourcingTeamMember") ? [
        {
            field: "stateManager.state",
            operator: "ne",
            value: "draft"
        }
    ] : [];

    let savedFilters = userObj?.preferences?.entityListView?.find(x => x.id === RESOURCE_PATH.RESOURCINGREQUEST)?.filters || [];
    let savedSortOrder = userObj?.preferences?.entityListView?.find(x => x.id === RESOURCE_PATH.RESOURCINGREQUEST)?.sortOrder;
    let savedPageSize = userObj?.preferences?.entityListView?.find(x => x.id === RESOURCE_PATH.RESOURCINGREQUEST)?.pageSize || 10;
    let initialSortOrder: CrudSorting = (savedSortOrder && savedSortOrder.length > 0) ? savedSortOrder : [{
        field: "updatedAt",
        order: "desc"
    }];

    const { tableProps, tableQueryResult, searchFormProps, sorter, setSorter, setPageSize } = useTable<IResourcingRequest, HttpError, IResourcingRequestFilterVariables>({
        dataProviderName: DATAPROVIDER_READ,
        resource: RESOURCE_PATH.RESOURCINGREQUEST,
        initialSorter: initialSortOrder,
        initialFilter: savedFilters,
        initialPageSize: savedPageSize,
        permanentFilter: resourcingFilter,
        onSearch: (params) => {
            const filters: CrudFilters = generateCrudFilters(params, "render");
            setSorter(generateSortOrder(params, initialSortOrder));
            setPageSize(generatePageSize(params));
            return filters;
        },
        queryOptions: {
            enabled: (savedFilters !== undefined)
        }

    });

    const { data: metadata } = useOne<IResourcingRequestMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.RESOURCINGREQUEST,
        queryOptions: {
            enabled: true,
            staleTime: STALE_DURATION
        }
    });

    const totalRequests = tableQueryResult?.data?.total ?? 0;
    const helpFilePath = metadata?.data?.fields?.find(x => x.name === "help")?.attachment || "";
    const metaConfig = metadata?.data?.config;
    const [filterOpen, switchFilterForm] = useState(false);
    const [helpOpen, switchHelpContent] = useState(false);
    const columnPreference = userObj?.preferences?.entityListView?.find(x => x.id === RESOURCE_PATH.RESOURCINGREQUEST)?.columns;
    const entityStates = metadata?.data?.states as IState[] ?? [];
    const [columnSelectionDrawer, switchColumnSelection] = useState(false);
    const [tableColumns, setTableColumns] = useState<IEntityListColumns>();
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

    useEffect(() => {
        if (metaConfig) {
            setTableColumns(mergePreferences(metaConfig.listColumns, columnPreference, RESOURCE_PATH.RESOURCINGREQUEST));
        }
    }, [metaConfig, columnPreference]);

    const getTableColumns = (): ColumnsType<IResourcingRequest> => {
        const columns: ColumnsType<IResourcingRequest> = GetListTableColumns(tableColumns, sorter, RESOURCE_PATH.RESOURCINGREQUEST);
        return columns
    }
    const OnColumnSelection = () => {
        switchColumnSelection(false);
    }

    const clearFilters = () => {
        //Switch to System Default 
        saveToProfile(preferencesData(userObj.preferences, RESOURCE_PATH.RESOURCINGREQUEST, undefined, [], [], 10), true);
    }

    const { mutate } = useUpdate<IBaseProps>();
    const saveToProfile = (userPreferences: IUserPreferences, clear: boolean = false) => {
        // Save the preference to profile
        mutate({
            dataProviderName: DATAPROVIDER_UPDATE,
            resource: RESOURCE_PATH.PROFILE,
            id: userObj.id,
            values: { preferences: userPreferences, _skipTimeStamp: true },
            successNotification: false,
            errorNotification: false
        },
            {
                onSuccess: () => {
                    //If clear, reset the form to facttory default
                    if (clear) {
                        searchFormProps.form?.setFieldsValue({});
                        searchFormProps.form?.submit();
                    }

                    // Remove the user Profile local data to ensure latest preferences are loaded
                    removeLocalSessionStorage(MY_PROFILE);
                },
                onError: () => {
                }
            }
        );
    }

    let isFiltered = savedFilters && savedFilters.length > 0 && savedFilters.some(fltr => fltr.value !== undefined)

    const { triggerExport, isLoading: exportLoading } = useExport<any>({
        dataProviderName: DATAPROVIDER_EXPORT,
        resourceName: RESOURCE_PATH.RESOURCINGREQUEST,
        pageSize: metaConfig?.export.pageSize ?? 100,
    });

    return (
        <List
            headerProps={{
                title: (tableQueryResult.isLoading ? "Loading" : totalRequests) + " Resourcing Request" + (totalRequests !== 1 ? "s" : ""),
                tags: <>
                    <Button key="filter" type="default"
                        icon={isFiltered ? <Icons.FilterFilled /> : <Icons.FilterOutlined />}
                        style={{ paddingRight: (isFiltered ? 4 : 15) }}
                        title={isFiltered ? "You are viewing a filtered list" : (metadata?.data?.config?.search?.textMessages?.buttonText || "Search & Filter")}
                        onClick={() => switchFilterForm(true)}>
                        Search
                        {isFiltered &&
                            <>
                                <Button
                                    type="text"
                                    danger
                                    title={"Clear Filters"}
                                    size="small"
                                    style={{ marginLeft: 5 }}
                                    onClick={(e) => {
                                        clearFilters();
                                        e.stopPropagation();
                                    }}
                                    icon={<Icons.CloseOutlined />}
                                >
                                </Button>
                            </>
                        }
                    </Button>
                </>
            }}

            headerButtons={() => (
                <>
                    <CanAccess resource={RESOURCE_PATH.RESOURCINGREQUEST} action="create" params={{ id: "1", dataProviderName: DATAPROVIDER_CREATE }}>
                        <CreateButton icon={<Icons.PlusOutlined />}>Add</CreateButton>
                    </CanAccess>
                    {metaConfig?.export.enabled &&
                        <CanAccess key={RESOURCE_PATH.POSITION} resource={RESOURCE_PATH.RESOURCINGREQUEST} action="list" params={{ dataProviderName: DATAPROVIDER_EXPORT }}>
                            <ExportButton onClick={triggerExport} loading={exportLoading} />
                        </CanAccess>
                    }
                    {/* <RequestImport/> */}
                    <Button key="colmnopt" title="Column Options" icon={<Icons.ToolOutlined />} onClick={() => switchColumnSelection(true)}></Button>
                    {helpFilePath &&
                        <Button key="help"
                            title="Help & FAQs"
                            icon={<Icons.ReadOutlined />} onClick={() => switchHelpContent(true)}></Button>
                    }
                    {entityStates.length > 0 ? RenderWorkflowModal(entityStates) : <></>}
                </>
            )}
        >
            <Descriptions column={3}>
                <Descriptions.Item label="Total" labelStyle={{ fontSize: 24 }}>
                    <StatsTile
                        listProps={{
                            resource: RESOURCE_PATH.RESOURCINGREQUEST,
                            dataProviderName: DATAPROVIDER_COUNT,
                        }} />
                </Descriptions.Item>

                <Descriptions.Item label="Submitted" labelStyle={{ fontSize: 24 }}>
                    <StatsTile
                        listProps={{
                            resource: RESOURCE_PATH.RESOURCINGREQUEST,
                            dataProviderName: DATAPROVIDER_COUNT,
                            config: {
                                filters: [{
                                    field: "stateManager.state",
                                    operator: "eq",
                                    value: "submitted"
                                }]
                            }
                        }} />
                </Descriptions.Item>
                <Descriptions.Item label="Published" labelStyle={{ fontSize: 24 }}>
                    <StatsTile
                        listProps={{
                            resource: RESOURCE_PATH.RESOURCINGREQUEST,
                            dataProviderName: DATAPROVIDER_COUNT,
                            config: {
                                filters: [{
                                    field: "stateManager.state",
                                    operator: "eq",
                                    value: "published"
                                }]
                            }
                        }} />
                </Descriptions.Item>
            </Descriptions>
            <Table {...tableProps}
                rowKey="id"
                pagination={{
                    ...tableProps.pagination,
                    position: ["bottomRight"],
                    showTotal: (total => <Typography.Title level={4} style={{ marginRight: 10 }} >Total {total}</Typography.Title>)
                }}
                columns={getTableColumns()}

                expandable={{
                    expandRowByClick: true,
                    expandedRowKeys: expandedRowKeys,
                    onExpand: (expanded, rec) => {
                        if (expanded) {
                            setExpandedRowKeys([rec.id]);
                        }
                        else {
                            setExpandedRowKeys([]);
                        }
                    },
                    expandedRowRender: record => <RequestExpand {...record} />
                }}
            />
            <Drawer
                title={metadata?.data?.config?.search?.textMessages?.drawerFormHeader || "Filter Requests"}
                placement="right"
                size="large"
                open={filterOpen}
                maskClosable={false}
                onClose={() => { switchFilterForm(false) }}
                forceRender={true}
            >
                {metaConfig &&
                    <RequestFilter
                        formProps={searchFormProps}
                        user={userObj}
                        onApplyFilter={() => switchFilterForm(false)}
                        defaultFilters={mapFiltersToFormFields(savedFilters, initialSortOrder, savedPageSize)}
                        listColumns={metaConfig.listColumns}
                    />
                }
            </Drawer>
            {(helpFilePath) &&
                <Drawer
                    title="FAQs"
                    placement="right"
                    size="large"
                    open={helpOpen}
                    onClose={() => { switchHelpContent(false) }}
                >
                    <RenderMDFile mdFilePath={helpFilePath} resourceName={RESOURCE_PATH.RESOURCINGREQUEST} />
                </Drawer>
            }
            <Drawer
                title="Column options"
                placement="right"
                size="default"
                open={columnSelectionDrawer}
                onClose={() => { switchColumnSelection(false) }}
            >
                <ListColumnPicker user={userObj} entityListColumns={tableColumns} onColumnSelection={OnColumnSelection} />
            </Drawer>
        </List>
    );
};

import { Create, DatePicker, Form, Input, SaveButton, Select, useForm, useSelect } from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { CancelButton } from "components/utils/cancelButton";
import { Dayjs } from "dayjs";
import { IEngagement, ILookup } from "interfaces";
import { useState } from "react";
import { DATAPROVIDER_CREATE, DATAPROVIDER_LOOKUP, RESOURCE_PATH } from "scripts/site";

export const EngagementCreate: React.FC<IResourceComponentsProps> = () => {

    const { formProps, saveButtonProps } = useForm<IEngagement>({
        dataProviderName: DATAPROVIDER_CREATE,
        redirect: "list"
    });

    const { selectProps: deliveryProps } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.PEOPLE,
        optionLabel: "nameEmail",
        optionValue: "id",
        filters: [{
            field: "roleIds ARRAY_CONTAINS",
            operator: "eq",
            value: "deliveryExecutive"
        }],
        sort: [{
            field: "lastAccessedAt",
            order: "desc"
        }],
    });

    const { selectProps: salesProps } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.PEOPLE,
        optionLabel: "nameEmail",
        optionValue: "id",
        filters: [{
            field: "roleIds ARRAY_CONTAINS",
            operator: "eq",
            value: "salesExecutive"
        }],
        sort: [{
            field: "lastAccessedAt",
            order: "desc"
        }],
    });

    const { selectProps: otherUserProps } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.PEOPLE,
        optionLabel: "nameEmail",
        optionValue: "id",
        filters: [{
            field: "roleIds ARRAY_CONTAINS",
            operator: "eq",
            value: "engagementAuthorizedUser"
        }],
        sort: [{
            field: "lastAccessedAt",
            order: "desc"
        }],
    });

    const { selectProps: clients } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.PEOPLE,
        optionLabel: "nameEmail",
        optionValue: "id",
        filters: [{
            field: "roleIds ARRAY_CONTAINS",
            operator: "eq",
            value: "client"
        }],
        sort: [{
            field: "lastAccessedAt",
            order: "desc"
        }],
    });

    const [startDate, setStartDate] = useState<Dayjs>();

    const { selectProps: serviceLineList } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.SERVICELINE,
        optionLabel: "name",
        optionValue: "id",
        sort: [{
            field: "name",
            order: "asc"
        }]
    });

    return (
        <Create
            footerButtons={() => (
                <>
                    <SaveButton {...saveButtonProps} />
                    <CancelButton />
                </>
            )}
        >
            <Form {...formProps} layout="vertical" autoComplete="off">
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            min: 5,
                            max: 100
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Description"
                    name="description"
                    rules={[
                        {
                            required: false,
                            whitespace: true,
                            min: 15,
                            max: 200
                        },
                    ]}
                >
                    <Input.TextArea rows={2} placeholder="Short abstract about the engagement" />
                </Form.Item>

                <Form.Item
                    label="Business Group"
                    name="businessGroup"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            max: 100
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Start Date"
                    name="startDate"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            type: "date"
                        }
                    ]}
                >
                    <DatePicker picker="date"
                        style={{ width: 280 }}
                        placeholder="YYYY-MM-DD"
                        //disabledDate={(selDate) => selDate && selDate < minStartDate}
                        onChange={(val) => val && setStartDate(val)}
                    />
                </Form.Item>


                <Form.Item
                    label="End Date"
                    name="endDate"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            type: "date"
                        }
                    ]}
                >
                    <DatePicker picker="date"
                        style={{ width: 280 }}
                        placeholder="YYYY-MM-DD"
                        disabledDate={(selDate) => selDate && !!startDate && selDate < startDate}
                    />
                </Form.Item>

                <Form.Item
                    label="Service Lines"
                    name="serviceLineIds"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            type: "array",
                            min: 1,
                        },
                    ]}
                >
                    <Select placeholder="Search Service Lines" mode="multiple" {...serviceLineList} allowClear />
                </Form.Item>

                <Form.Item
                    label="Sales Executives"
                    name="salesExecutives"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            type: "array"
                        },
                    ]}
                >
                    <Select placeholder="Search Name or Email" mode="multiple" {...salesProps} allowClear />
                </Form.Item>

                <Form.Item
                    label="Delivery Executives"
                    name="deliveryExecutives"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            type: "array",
                        },
                    ]}
                >
                    <Select placeholder="Search Name or Email" mode="multiple"  {...deliveryProps} allowClear />
                </Form.Item>

                <Form.Item
                    label="Other Authorized users"
                    name="otherAuthorizedUsers"
                    rules={[
                        {
                            required: false,
                            whitespace: true,
                            type: "array",
                        },
                    ]}
                >
                    <Select placeholder="Search Name or Email" mode="multiple"  {...otherUserProps} allowClear />
                </Form.Item>

                <Form.Item
                    label="Clients"
                    name="clients"
                    rules={[
                        {
                            required: false,
                            whitespace: true,
                            type: "array",
                        },
                    ]}
                >
                    <Select placeholder="Search Name or Email" mode="multiple"  {...clients} allowClear />
                </Form.Item>
            </Form>
        </Create>
    );
};
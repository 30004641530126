import { ILookup } from "@architecture-innovation-transformation/lib-common";
import { Button, Form, FormProps, Icons, Input, Select, Space, useSelect } from "@pankod/refine-antd";
import { useOne } from "@pankod/refine-core";
import { RenderSpinner } from "components/common";
import { IIntakeActivityMetadata } from "interfaces/intake";
import { DATAPROVIDER_LOOKUP, RESOURCE_PATH, STALE_DURATION } from "scripts/site";

export const IntakeActivityFilter: React.FC<{
    formProps: FormProps,
    onApplyFilter: () => void,
}> = (props) => {
    const { isLoading, data: metadata } = useOne<IIntakeActivityMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.INTAKE_ACTIVITY,
        queryOptions: {
            enabled: true,
            staleTime: STALE_DURATION
        }
    });

    const { selectProps: userList } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.PEOPLE,
        optionLabel: "nameEmail",
        optionValue: "id",
        sort: [{
            field: "lastAccessedAt",
            order: "desc"
        }]
    });


    const metaConfig = metadata?.data.config;
    return (
        isLoading ? <RenderSpinner message="Loading..." ></RenderSpinner> :
            <Form layout="vertical" {...props.formProps} autoComplete="off">
                <Form.Item
                    label={"Search"}
                    name="q"
                    tooltip={metaConfig?.search?.textMessages?.searchTextPlaceholder || "Search Intake Request"}
                >
                    <Input
                        placeholder={metaConfig?.search?.textMessages?.searchTextPlaceholder || "Search Intake Request"}
                        prefix={<Icons.SearchOutlined />}
                    />
                </Form.Item>
                <Form.Item
                    label="Created By"
                    name="createdBy"
                    tooltip="To look up any missing name type the name of the person and then select"
                >
                    <Select
                        allowClear
                        placeholder="Intake activity created by"
                        mode="multiple"
                        {...userList}
                    >
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Updated By"
                    name="updatedBy"
                    tooltip="To look up any missing name type the name of the person and then select"
                >
                    <Select
                        allowClear
                        placeholder="Intake activity updated by"
                        mode="multiple"
                        {...userList}
                    >
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Space>
                        <Button
                            htmlType="submit"
                            type="primary"
                            icon={<Icons.FilterOutlined />}
                            onClick={() => {
                                props.onApplyFilter();
                            }}
                        >
                            Apply
                        </Button>
                        <Button
                            icon={<Icons.ClearOutlined />}
                            htmlType="submit"
                            onClick={() => { props.formProps.form?.resetFields() }}
                        >
                            Clear
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
    );
}
import { Form, Input, Modal, Select, useSelect } from "@pankod/refine-antd";
import { useList } from "@pankod/refine-core";
import { IBase, IState, IStateTransitionComments, stateAssignedTo } from "interfaces";
import { useEffect } from "react";
import { DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, FETCH_ACTION, RESOURCE_PATH } from "scripts/site";

export declare type modalFormProps = {
  visible: boolean,
  modalTitle: string;
  onCancel: () => void;
  onSave: (values: IStateTransitionComments) => void;
  stateAction: string;
  entityType: string;
  entityId: string;
  currentState?: IState;
  fetchAssignedToList: boolean;
};

export const RenderCommentsModal: React.FC<modalFormProps> = ({
  visible,
  modalTitle,
  onCancel,
  onSave,
  stateAction,
  entityType,
  entityId,
  currentState,
  fetchAssignedToList
}) => {
  const [form] = Form.useForm<IStateTransitionComments>();
  // const { data: userData } = useGetIdentity();
  // const record = userData as IUser;

  const { data, isLoading } = useList<stateAssignedTo>({
    dataProviderName: DATAPROVIDER_READ,
    resource: `${entityType}/${entityId}/${FETCH_ACTION}`,
    config: {
      filters: [
        {
          field: "operation",
          operator: "eq",
          value: "assignedToList"
        }
      ]
    },
    queryOptions: {
      enabled: fetchAssignedToList
    }
  });

  const probableState = currentState?.nextStates.find(st => st.action === stateAction);
  const computeAction = probableState?.computeAssignedToOnAction ? true : false;
  const dynamicTitle = probableState?.commentsTitle;
  //var assignedToList = data ? data.data.find(st => st.state === probableState?.stateId)?.assignedToList : [];

  useEffect(() => {
    var assignedToList = data ? data.data.find(st => st.state === probableState?.stateId)?.assignedToList : [];
    if (assignedToList) {
      form.setFieldsValue({
        assignedTo: assignedToList
      });
    }
  }
    , [data, form, probableState]);

  useEffect(() => {
    const defaultComments = probableState?.predefinedComments;
    if (defaultComments) {
      form.setFieldsValue({
        comments: defaultComments
      });
    }
  }
    , [form, probableState]);

  // if (defaultComments) {
  //   form.setFieldValue("comments", defaultComments);
  // }

  // if (assignedToList?.length !== 0) {
  //   form.setFieldValue("assignedTo", assignedToList);
  // }

  //console.log("assignedToList", assignedToList);
  const { selectProps: allUsersProps } = useSelect<IBase>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.PEOPLE,
    optionLabel: "nameEmail",
    optionValue: "id",
    // commenting out this code to allow self-assignment
    // filters: [{
    //   field: "id",
    //   operator: "ne",
    //   value: record?.id
    // }],
    sort: [
      {
        field: "lastAccessedAt",
        order: "desc"
      }],
    queryOptions: {
      enabled: visible
    }
  });

  return (
    <Modal
      forceRender={true}
      open={visible}
      title={dynamicTitle ?? modalTitle}
      maskClosable={true}
      closable={true}
      okText={probableState?.buttonText}
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            onSave(values);
          });
      }}
    >
      <Form
        autoComplete="off"
        form={form}
        layout="vertical"
        // initialValues={{
        //   comments: defaultComments,
        //   assignedTo: assignedToList,
        //   computeOnAction: computeAction
        // }}
        fields={[
          // {
          //   name: ["comments"],
          //   value: defaultComments
          // },
          // {
          //   name: ["assignedTo"],
          //   value: assignedToList
          // },
          {
            name: ["computeOnAction"],
            value: computeAction
          }
        ]
        }
      >
        <Form.Item
          name="comments"
          label="Comments"
          rules={[
            {
              required: true,
              message: 'Please provide comments!'
            }
          ]}
        >
          <Input //ref={input => input && input.focus()} 
            maxLength={75} placeholder="Please provide comments!" />
        </Form.Item>
        <Form.Item
          name="assignedTo"
          label={probableState?.transitionText || "Assign workflow to"}
          hidden={!probableState?.showAssignedTo}
          tooltip={probableState?.transitionTooltipText || `As per rule configuration, the ${entityType} will be assigned to below user(s), please update if you wish to assign to other available users.`}
          rules={[
            {
              required: probableState?.showAssignedTo,
              whitespace: true,
              type: "array",
              max: probableState?.multipleAssignedTo ? 5 : 1
            }
          ]}
        >
          <Select placeholder="Search Name or Email" listHeight={216} loading={isLoading}
            mode="multiple"
            disabled={!probableState?.showAssignedTo}
            {...allUsersProps}
            allowClear
            style={{ position: 'relative' }}
          />
        </Form.Item>
        <Form.Item
          name="computeOnAction"
          hidden={true}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
import { Col, DatePicker, Edit, Form, Input, Row, SaveButton, Select, useForm, useSelect } from "@pankod/refine-antd";
import { useOne } from "@pankod/refine-core";
import MDEditor from "@uiw/react-md-editor";
import { CancelButton } from "components/utils/cancelButton";
import dayjs from "dayjs";
import { ILookup } from "interfaces";
import { IIntake, IIntakeMetadata } from "interfaces/intake";
import React, { useState } from "react";
import { DATAPROVIDER_LOOKUP, DATAPROVIDER_UPDATE, RESOURCE_PATH, STALE_DURATION } from "scripts/site";
import { getAppTheme } from "scripts/theme";

export const IntakeEdit: React.FC = () => {
    const { form, formProps, saveButtonProps, queryResult } = useForm<IIntake>({
        dataProviderName: DATAPROVIDER_UPDATE,
        redirect: "show"
    });
    const record = queryResult?.data?.data;

    if (queryResult?.data?.data?.realizationDate) {
        queryResult.data.data.realizationDate = dayjs(queryResult?.data?.data?.realizationDate);
    }

    if (queryResult?.data?.data?.requestDeadline) {
        queryResult.data.data.requestDeadline = dayjs(queryResult?.data?.data?.requestDeadline);
    }

    const { selectProps: smesList } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.PEOPLE,
        optionLabel: "nameEmail",
        optionValue: "id",
        sort: [{
            field: "lastAccessedAt",
            order: "desc"
        }]
    });

    const { selectProps: salesExecList } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.PEOPLE,
        optionLabel: "nameEmail",
        optionValue: "id",
        filters: [{
            field: "roleIds ARRAY_CONTAINS",
            operator: "eq",
            value: "salesExecutive"
        }],
        sort: [{
            field: "lastAccessedAt",
            order: "desc"
        }],
    });

    const { selectProps: deliveryExecList } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.PEOPLE,
        optionLabel: "nameEmail",
        optionValue: "id",
        filters: [{
            field: "roleIds ARRAY_CONTAINS",
            operator: "eq",
            value: "deliveryExecutive"
        }],
        sort: [{
            field: "lastAccessedAt",
            order: "desc"
        }],
    });

    const { data: metadata } = useOne<IIntakeMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.INTAKE,
        queryOptions: {
            enabled: true,
            staleTime: STALE_DURATION
        }
    });

    const metaConfig = metadata?.data.config;
    const threeColumnLayout = { xs: { span: 24 }, sm: { span: 24 }, md: { span: 8 }, lg: { span: 8 }, xl: { span: 8 }, xxl: { span: 8 } };
    const rowGutter = { xs: 8, sm: 16, md: 24, lg: 32 };
    const [validateForm] = useState<boolean>(true);
    let isNotesEnabled = record?.stateManager?.state ? metaConfig?.notesAllowedStates?.includes(record?.stateManager?.state) : false;

    return (
        <Edit
            isLoading={queryResult?.isFetching}
            title={`Update - ${queryResult?.isFetching ? "" : record?.name}`}
            headerButtons={() => (
                <>
                </>
            )}
            footerButtons={<>
                <SaveButton {...saveButtonProps}>Save</SaveButton>
                <CancelButton />
            </>}>
            <Form {...formProps} layout="vertical" autoComplete="off"
                scrollToFirstError={true}
            >
                <Form.Item
                    label="Title"
                    name="name"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            message: "Title is required.",
                            min: 5,
                            max: 250
                        },
                    ]}
                >
                    <Input placeholder="Title for the intake" />
                </Form.Item>
                <Form.Item
                    label="Business Group"
                    name="businessGroup"
                    rules={[
                        {
                            required: validateForm,
                            type: "string",
                        },
                    ]}
                >
                    <Select placeholder="Select Business Group"
                        showSearch
                    >
                        {metaConfig?.businessGroups?.map((m) => (
                            <Select.Option value={m} key={`lc-${m}`}>{m}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Row gutter={rowGutter}>
                    <Col className="gutter-row" {...threeColumnLayout}>
                        <Form.Item
                            label="Request Type"
                            name="requestType"
                            rules={[
                                {
                                    required: validateForm,
                                    type: "string",
                                },
                            ]}
                        >
                            <Select placeholder="Select Request Type"
                                onChange={(_value) => {
                                    form.setFieldsValue({ 'requestSubType': null });
                                }}
                                showSearch
                            >
                                {metaConfig?.requestType?.map((m) => (
                                    <Select.Option value={m.requestTypeName} key={`lc-${m.requestTypeName}`}>{m.requestTypeName}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" {...threeColumnLayout}>
                        {<Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues.requestType !== currentValues.requestType}
                        >
                            {({ getFieldValue }) =>
                                <Form.Item
                                    label="Request Sub Type"
                                    name="requestSubtype"
                                    tooltip="Select the request subtype"
                                    rules={[
                                        {
                                            required: validateForm,
                                            type: "string"
                                        }
                                    ]}
                                >
                                    <Select placeholder={form.getFieldValue('requestType') ? "Select Request Sub Type" : "Select request type first"} showSearch>
                                        {metaConfig?.requestType?.filter(rst => rst.requestTypeName === getFieldValue("requestType")).map((m) => (
                                            m.requestSubType?.map((r) => (
                                                <Select.Option value={r} key={`rst-${r}`}>{r}</Select.Option>
                                            ))
                                        ))}
                                    </Select>
                                </Form.Item>
                            }
                        </Form.Item>
                        }
                    </Col>
                    <Col className="gutter-row" {...threeColumnLayout}>
                        <Form.Item
                            label="Revenue Opportunity (in $ value)"
                            name="opportunity"
                            rules={[
                                {
                                    required: true,
                                    type: "number",
                                    validator: async (_, value) => {
                                        if (value && !Number.isInteger(value) && value < 0) {
                                            throw new Error("Opportunity value must be valid a positive number.");
                                        }
                                    },
                                }
                            ]}
                        >
                            <Input placeholder="What is the $ value for the opportunity" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={rowGutter}>
                    <Col className="gutter-row" {...threeColumnLayout}>
                        {
                            <Form.Item
                                label="Start of Revenue Realization (Month, Year)"
                                name="realizationDate"
                                tooltip="Select the month and year for $ value realization"
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        type: "date"
                                    }
                                ]}
                            >
                                <DatePicker picker="month" />
                            </Form.Item>
                        }
                    </Col>
                    <Col className="gutter-row" {...threeColumnLayout}>
                        {
                            <Form.Item
                                label="Revenue Realization Duration (Months)"
                                name="realizationDurationMonths"
                                tooltip="Number of months the $ value would be realized"
                                rules={[
                                    {
                                        required: true,
                                        type: "number",
                                        validator: async (_, value) => {
                                            if (value && !Number.isInteger(value) && value < 0) {
                                                throw new Error("Realization Duration (Months) must be a positive integer.");
                                            }
                                        },
                                    }
                                ]}
                            >
                                <Input placeholder="What is the duration to realize the $ value" />
                            </Form.Item>
                        }
                    </Col>
                    <Col className="gutter-row" {...threeColumnLayout}>
                        {
                            <Form.Item
                                label="Request Deadline"
                                name="requestDeadline"
                                tooltip="Date by when this request needs to be completed."
                                rules={[
                                    {
                                        required: false,
                                        whitespace: true,
                                        type: "date"
                                    }
                                ]}
                            >
                                <DatePicker defaultValue={dayjs(Date.now()).add(15, 'days')}
                                    disabledDate={d => !d || d.isBefore(dayjs(Date.now()).add(14, 'days'))} format={"MMM DD, YYYY"} />
                            </Form.Item>
                        }
                    </Col>
                </Row>
                <Row gutter={rowGutter}>
                    <Col className="gutter-row" {...threeColumnLayout}>
                        <Form.Item
                            label="SME(s)"
                            name="smes"
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    type: "array",
                                    message: "SME(s) are required."
                                },
                            ]}
                        >
                            <Select placeholder="Search name or email of the SME(s)" mode="multiple"  {...smesList} allowClear />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" {...threeColumnLayout}>
                        <Form.Item
                            label="Delivery Executive"
                            name="deliveryExecutive"
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    type: "string",
                                    message: "Delivery Executive is required."
                                },
                            ]}
                        >
                            <Select placeholder="Search name or email of the delivery executive" {...deliveryExecList} allowClear />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" {...threeColumnLayout}>
                        <Form.Item
                            label="Sales Executive"
                            name="salesExecutive"
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    type: "string",
                                    message: "Sales Executive is required."
                                },
                            ]}
                        >
                            <Select placeholder="Search name or email of the sales executive"  {...salesExecList} allowClear />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    label="Problem Statement"
                    name="problemStatement"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            min: 5
                        },
                    ]}
                >
                    <MDEditor height={250} data-color-mode={getAppTheme()} placeholder="Explain the problem statement in detail for which you need help." />
                </Form.Item>
                <Form.Item
                    label="Support Needed"
                    name="supportNeeded"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            min: 5
                        },
                    ]}
                >
                    <MDEditor height={250} data-color-mode={getAppTheme()} placeholder="Explain the support needed in detail." />
                </Form.Item>
                {isNotesEnabled ?
                    <Form.Item
                        label="Notes"
                        name="notes"
                    >
                        <MDEditor height={250} data-color-mode={getAppTheme()} placeholder="Record any notes here" />
                    </Form.Item>
                    : <></>
                }
            </Form>
        </Edit>
    );
}
import { IBaseProps, stringExtensions } from "@architecture-innovation-transformation/lib-common";
import { Button, DatePicker, Descriptions, Divider, Edit, Form, InputNumber, Result, Spin, Tag, Typography, useForm } from "@pankod/refine-antd";
import { IResourceComponentsProps, useCan, useNavigation, useOne } from "@pankod/refine-core";
import { StateButtons } from "components/common";
import { CancelButton } from "components/utils/cancelButton";
import dayjs from "dayjs";
import { IStateTransitionComments } from "interfaces";
import { IPosition, IPositionConfigSet, IPositionMetadata } from "interfaces/resourcing";
import { useState } from "react";
import { DATAPROVIDER_CONFIGSET, DATAPROVIDER_LOOKUP, DATAPROVIDER_UPDATE, RESOURCE_PATH, STALE_DURATION } from "scripts/site";

export const PositionEdit: React.FC<IResourceComponentsProps> = () => {
    const { form, formProps, queryResult } = useForm<IPosition>({
        dataProviderName: DATAPROVIDER_UPDATE
    });

    if (queryResult?.data?.data?.billingStartDate) {
        queryResult.data.data.billingStartDate = dayjs(queryResult.data.data.billingStartDate);
    }

    const record = queryResult?.data?.data;
    const [validateForm, setValidateForm] = useState<boolean>(true);
    const [disableForm, setDisableForm] = useState<boolean>(false);

    const handleFormSave = (values: IStateTransitionComments, stateAction: string) => {
        setDisableForm(true);
        setValidateForm(true);
        form.validateFields().then(async () => {
            form.setFieldsValue({
                'stateAction': stateAction,
                'comments': values.comments
            })
            form.submit();
        })
            .catch(() => {
                setDisableForm(false);
            });
    }

    const setFormPreValidationRules = (stateAction: string) => {
        setDisableForm(true);
    }

    const onFailureFormValidation = () => {
        setDisableForm(false);
    }

    const { data: metadata, isLoading: positionMetadataLoading } = useOne<IPositionMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.POSITION,
        queryOptions: {
            enabled: true,
            staleTime: STALE_DURATION
        }
    });
    const metaConfig = metadata?.data.config;

    const { data: configSet, isLoading: configSetLoading } = useOne<IPositionConfigSet>({
        dataProviderName: DATAPROVIDER_CONFIGSET,
        resource: RESOURCE_PATH.POSITION,
        id: record?.id ?? "",
        queryOptions: {
            enabled: !!record?.id
        }
    });

    // Set the rules for the request based on the config set. If config set is not found, then use the metadata config as default.
    const configSetRules = configSet?.data?.values ?? metaConfig?.defaultConfigSet;

    const canEdit = useCan({
        resource: RESOURCE_PATH.POSITION,
        action: "edit",
        params: {
            dataProviderName: DATAPROVIDER_UPDATE,
            id: record?.id
        },
        queryOptions: {
            enabled: configSetRules && configSetRules?.editableStates?.includes(record?.stateManager?.state ?? "") && !!record?.id
        }
    });
    const { list } = useNavigation();

    if (queryResult?.data?.data?.billingStartDate) {
        queryResult.data.data.billingStartDate = dayjs(queryResult.data.data.billingStartDate);
    }

    return (
        (queryResult?.isLoading || positionMetadataLoading || (record?.id && configSetLoading))
            ? <Result
                status="info"
                title="Checking permission !!!"
                subTitle="Kindly wait for few moments while we verify your permission and load this request for you."
            />
            : configSetRules && (canEdit.data?.can ?? true) && configSetRules?.canRescopePosition && configSetRules?.editableStates?.includes(record?.stateManager?.state ?? "")
                ? <Spin spinning={disableForm}>
                    <Edit
                        headerProps={{
                            title: "Resourcing Position",
                            subTitle: `for ${record?.name}`,
                            tags: record && <Tag color="blue" >{stringExtensions.capitalize(record?.stateManager?.state)}</Tag>,
                            extra: []
                        }}

                        footerButtons={() => (
                            <>
                                {record && record?.billable === "Billable" &&
                                    <StateButtons {...record as IBaseProps}
                                        resourceToAct={RESOURCE_PATH.POSITION}
                                        resourceToActId={record?.id || "0"}
                                        resourceToNavigate={RESOURCE_PATH.POSITION}
                                        hideActions={['open', 'hold', 'mapped', 'cancel', 'lost', 'closed', 'requestClosure']}
                                        skipAssignedToCheck={true}
                                        form={form}
                                        setFormPreValidationRules={setFormPreValidationRules}
                                        onFailureFormValidate={onFailureFormValidation}
                                        customMutate={handleFormSave}
                                    />
                                }
                                <CancelButton />
                            </>
                        )}
                    >
                        {record?.billable === "Billable"
                            ? <>
                                <Form {...formProps} form={form} layout="vertical" autoComplete="off" scrollToFirstError={true}>
                                    <Descriptions column={4}>
                                        <Descriptions.Item label="RGS ID" labelStyle={{ fontSize: 15 }}>
                                            <Typography.Text copyable>{record?.rgsId}</Typography.Text>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Request ID" labelStyle={{ fontSize: 15 }}>
                                            <Typography.Text copyable>{record?.requestID}</Typography.Text>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Position ID" labelStyle={{ fontSize: 15 }}>
                                            <Typography.Text copyable>{record?.id}</Typography.Text>
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider style={{ marginTop: 5 }} />
                                    <Form.Item
                                        label="Billing Start Date"
                                        name="billingStartDate"
                                        tooltip="Provide a future date"
                                        rules={[
                                            {
                                                required: validateForm,
                                                whitespace: true,
                                                type: "date"
                                            }
                                        ]}
                                    >
                                        <DatePicker picker="date"
                                            style={{ width: 280 }}
                                            placeholder="YYYY-MM-DD"
                                            format={"YYYY-MM-DD"}
                                            disabledDate={(selDate) => selDate && (selDate < dayjs().endOf('day') || selDate > dayjs().add(6, 'months').endOf('day'))}
                                            allowClear={false}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Bill Rate (USD per Hour)"
                                        name="billRate"
                                        rules={[
                                            {
                                                required: validateForm,
                                                type: "integer"
                                            }
                                        ]}
                                    >
                                        <InputNumber min={0} max={200} precision={0} placeholder="0" addonAfter="$" style={{ width: 280 }} />
                                    </Form.Item>
                                </Form>
                            </>
                            : <Result
                                status="info"
                                title="Rescope is not applicable for this position."
                                subTitle="This position is Non-Billable. Rescope is only applicable for Billable positions."
                                extra={<Button type="primary" onClick={() => list(RESOURCE_PATH.POSITION)}>Back to Positions</Button>}
                            />
                        }
                    </Edit>
                </Spin>
                :
                <Result
                    status="403"
                    title="Edit operation not allowed."
                    subTitle="Sorry, In current state, you don't have permission to edit this position. Kindly contact Resourcing Team for any changes needed."
                    extra={<Button type="primary" onClick={() => list(RESOURCE_PATH.POSITION)}>Back to Positions</Button>}
                />
    );
};
import { Button, Icons, Menu } from "@pankod/refine-antd";
import { CanAccess, useNavigation, useResourceWithRoute } from "@pankod/refine-core";
import React from "react";
import { DATAPROVIDER_READ, RESOURCE_PATH } from "scripts/site";

export const IntakeSubMenu: React.FC<{
    intakeId: string,
    selectedResource?: string,
    renderAsMenu?: boolean,
    hideOthers?: boolean,
    hideText?: boolean
}> = ({ intakeId, selectedResource = "", renderAsMenu = true, hideOthers = false, hideText = false }) => {
    const { push } = useNavigation();
    const resourceWithRoute = useResourceWithRoute();

    function navigateWithIntakeId(resourceName: string) {
        console.log(resourceWithRoute(resourceName));
        push(`/${resourceWithRoute(resourceName).route}?intakeId=${intakeId}`);
    }

    return (
        renderAsMenu ?
            <Menu mode="horizontal" selectedKeys={[selectedResource]} theme={"light"}>
                <CanAccess key={RESOURCE_PATH.INTAKE} resource={RESOURCE_PATH.INTAKE.toLowerCase()} action="show">
                    <Menu.Item icon={<Icons.InfoOutlined />}
                        onClick={() => {
                            push(`/${resourceWithRoute(RESOURCE_PATH.INTAKE).route}/show/${intakeId}`);
                        }} style={{ display: (hideOthers && selectedResource !== RESOURCE_PATH.INTAKE) ? 'none' : '', marginLeft: '-8px', marginBottom: '2px' }} title="View Details">
                        {hideText ? "" : "Details"}
                    </Menu.Item>
                </CanAccess>
                <CanAccess key={RESOURCE_PATH.INTAKE_ACTIVITY} resource={RESOURCE_PATH.INTAKE_ACTIVITY.toLowerCase()} action="show"
                    params={{ dataProviderName: DATAPROVIDER_READ, id: "1", intakeId: intakeId, ignoreid: true }}>
                    <Menu.Item icon={<Icons.SolutionOutlined />} onClick={() => {
                        navigateWithIntakeId(RESOURCE_PATH.INTAKE_ACTIVITY);
                    }} style={{ display: (hideOthers && selectedResource !== RESOURCE_PATH.INTAKE_ACTIVITY) ? 'none' : '', marginLeft: '-8px', marginBottom: '2px' }} title="View Activities">
                        {hideText ? "" : "Activities"}
                    </Menu.Item>
                </CanAccess>
            </Menu>
            :
            <CanAccess key={RESOURCE_PATH.INTAKE_ACTIVITY} resource={RESOURCE_PATH.INTAKE_ACTIVITY.toLowerCase()} action="show"
                params={{ dataProviderName: DATAPROVIDER_READ, id: "1", intakeId: intakeId, ignoreid: true }}>
                <Button
                    title="View Activities"
                    size="small"
                    icon={<Icons.SolutionOutlined />}
                    onClick={() => {
                        navigateWithIntakeId(RESOURCE_PATH.INTAKE_ACTIVITY);
                    }}>
                    {hideText ? "" : "Activities"}
                </Button>
            </CanAccess>
    );
};
import { Divider, Edit, Form, Input, InputNumber, Radio, SaveButton, Select, TimePicker, Typography, useForm, useSelect } from "@pankod/refine-antd";
import { IResourceComponentsProps, useOne } from "@pankod/refine-core";
import { CancelButton } from "components/utils/cancelButton";
import { LookupButton } from "components/utils/lookupButton";
import dayjs, { Dayjs } from "dayjs";
import { ILookup, IRoom, IRoomMetadata } from "interfaces";
import { useState } from "react";
import { DATAPROVIDER_LOOKUP, DATAPROVIDER_UPDATE, RESOURCE_PATH, STALE_DURATION, greyStyle, handleSelectChange } from "scripts/site";

export const RoomEdit: React.FC<IResourceComponentsProps> = () => {
    const { form, formProps, saveButtonProps, queryResult } = useForm<IRoom>({
        dataProviderName: DATAPROVIDER_UPDATE,
        redirect: "list"
    });
    const record = queryResult?.data?.data;

    const { data: metadata } = useOne<IRoomMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.ROOM,
        queryOptions: {
            enabled: true,
            staleTime: STALE_DURATION
        }
    });

    const { selectProps: spaceAdminProps } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.PEOPLE,
        optionLabel: "nameEmail",
        optionValue: "id",
        filters: [{
            field: "roleIds ARRAY_CONTAINS",
            operator: "eq",
            value: "spaceContributor"
        }],
        sort: [
            {
                field: "lastAccessedAt",
                order: "desc"
            },
        ]
    });

    const { selectProps: userProps } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.PEOPLE,
        optionLabel: "nameEmail",
        optionValue: "id",
        sort: [
            {
                field: "lastAccessedAt",
                order: "desc"
            },
        ]
    });

    const { selectProps: groupProps } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.GROUPS,
        optionValue: "id",
        optionLabel: "name",
        sort: [
            {
                field: "name",
                order: "asc"
            },
        ]
    });

    const metaConfig = metadata?.data.config;

    const [align, setAlign] = useState(record?.spaces?.reservationRules?.alignReservations ?? false);
    const [privateFlag, setPrivate] = useState(record?.private ?? false);
    const [offlineFlag, setOffline] = useState(record?.offline ?? false);
    const [beginTime, setBegin] = useState(dayjs());
    const [endTime, setEnd] = useState(dayjs());

    const [isInitialLoad, setIsInitialLoad] = useState(true);
    if (isInitialLoad && !(queryResult?.isLoading ?? true) && record) {
        setIsInitialLoad(false);
        setAlign(record?.spaces?.reservationRules?.alignReservations ?? false);
        setPrivate(record?.private ?? false);
        setOffline(record?.offline ?? false);
        setBegin(dayjs("1970-01-01T" + (record?.spaces?.reservationRules?.beginTime ?? "00:00")))
        setEnd(dayjs("1970-01-01T" + (record?.spaces?.reservationRules?.endTime ?? "00:00")))
    }

    return (
        <Edit
            headerButtons={<></>}
            footerButtons={<>
                <SaveButton {...saveButtonProps} onClick={() => {
                    // remove empty fields before saving
                    form.validateFields().then(() => {
                        const values: any = form.getFieldValue(["spaces", "reservationRules"]);
                        Object.keys(values).forEach(key => (values[key] === undefined || values[key] === null || values[key] === "") && delete values[key]);
                        form.setFieldValue(["spaces", "reservationRules"], values);
                        form.submit();
                    }).catch(() => { });
                }} />
                <CancelButton />
            </>}
        >
            <Form {...formProps} layout="vertical" autoComplete="off">
                <Form.Item
                    name="id"
                    label="Room Id"
                    valuePropName="children"
                >
                    <Typography.Text></Typography.Text>
                </Form.Item>
                <Form.Item label="Location" tooltip="Location cannot be edited">
                    <LookupButton id={record?.countryId || ""} resource={RESOURCE_PATH.COUNTRY} noClick></LookupButton>
                    <Typography.Text style={greyStyle}>{" / "}</Typography.Text>
                    <LookupButton id={record?.locationId || ""} resource={RESOURCE_PATH.LOCATION} noClick></LookupButton>
                    <Typography.Text style={greyStyle}>{" / "}</Typography.Text>
                    <LookupButton id={record?.buildingId || ""} resource={RESOURCE_PATH.BUILDING} noClick></LookupButton>
                    <Typography.Text style={greyStyle}> {" / "}</Typography.Text>
                    <LookupButton id={record?.zoneId || ""} resource={RESOURCE_PATH.ZONE} noClick></LookupButton>
                </Form.Item>
                <Form.Item
                    name="name"
                    label="Room Name"
                    rules={[
                        {
                            required: true,
                            type: "string",
                            max: 50,
                            pattern: new RegExp(/^[a-zA-Z0-9 ()/-]+$/),
                        }
                    ]}
                >
                    <Input max={50} />
                </Form.Item>
                <Form.Item label="Occupancy" required style={{ marginBottom: 14 }}>
                    <Form.Item noStyle
                        name="minOccupancy"
                        label="Minimum Occupancy"
                        rules={[
                            {
                                required: true,
                                type: "number",
                                min: 1,
                                max: 250
                            },
                        ]}
                    >
                        <InputNumber min={1} max={250} step={1} precision={0} addonBefore="Min"
                            style={{ marginRight: 20, marginBottom: 10 }}
                            onChange={() => {
                                if (form.getFieldValue("maxOccupancy")) {
                                    form.validateFields(["maxOccupancy"]);
                                }
                            }}
                        />
                    </Form.Item>

                    <Form.Item noStyle
                        name="maxOccupancy"
                        label="Maximum Occupancy"
                        rules={[
                            {
                                required: true,
                                type: "number",
                                min: 1,
                                max: 250
                            },
                            {
                                validator: (_, value) => {
                                    let min = form.getFieldValue("minOccupancy") ?? 1;
                                    if (value < min) {
                                        return Promise.reject("Maximum occupancy must be greater than or equal to minimum occupancy.");
                                    }
                                    return Promise.resolve();
                                }
                            }
                        ]}
                    >
                        <InputNumber
                            style={{ marginRight: 20, marginBottom: 10 }}
                            min={1} max={250} step={1} precision={0} addonBefore="Max" />
                    </Form.Item>
                </Form.Item>
                <Form.Item
                    label="Private"
                    name="private"
                    rules={[
                        {
                            type: "boolean",
                            required: true
                        }
                    ]}
                >
                    <Radio.Group
                        optionType="button"
                        buttonStyle="solid"
                        name="private"
                        onChange={(e) => {
                            setPrivate(e.target.value);
                        }}
                    >
                        <Radio key="pr-01" value={true}>Yes</Radio>
                        <Radio key="pr-02" value={false}>No</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label="Offline"
                    name="offline"
                    rules={[
                        {
                            type: "boolean",
                            required: true
                        }
                    ]}
                >
                    <Radio.Group
                        optionType="button"
                        buttonStyle="solid"
                        name="offline"
                        onChange={(e) => {
                            setOffline(e.target.value);
                        }}
                    >
                        <Radio key="of-01" value={true}>Yes</Radio>
                        <Radio key="of-02" value={false}>No</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    name="remarks"
                    label="Remarks"
                    rules={[
                        {
                            required: offlineFlag,
                            whitespace: true
                        },
                    ]}
                >
                    <Input maxLength={200} />
                </Form.Item>

                <Divider dashed orientation="left">Administration</Divider>
                <Form.Item
                    label="Room Administrators"
                    name={["spaces", "administrators"]}
                    tooltip="Start typing to look up"
                    rules={[
                        {
                            whitespace: true,
                            type: "array",
                            required: privateFlag
                        },
                    ]}
                >
                    <Select {...spaceAdminProps}
                        placeholder="Search Name or Email"
                        mode="multiple"
                        allowClear
                        onChange={(e) => { handleSelectChange(form, e, "spaces.administrators") }}
                    />
                </Form.Item>
                <Form.Item
                    label="Power Users"
                    name={["spaces", "powerUsers"]}
                    tooltip="Start typing to look up"
                    rules={[
                        {
                            whitespace: true,
                            type: "array"
                        },
                    ]}
                >
                    <Select {...userProps}
                        placeholder="Search Name or Email"
                        mode="multiple"
                        allowClear
                        onChange={(e) => { handleSelectChange(form, e, "spaces.powerUsers") }}
                    />
                </Form.Item>
                <Form.Item
                    label="Power User Group"
                    name={["spaces", "powerUserGroup"]}
                    rules={[
                        {
                            whitespace: true,
                            type: "string"
                        },
                    ]}
                >
                    <Select {...groupProps}
                        placeholder="Search Groups"
                        //mode="multiple"
                        allowClear
                        onChange={(e) => { handleSelectChange(form, e, "spaces.powerUserGroup") }}
                    />
                </Form.Item>

                <Divider orientation="left">Accomodations</Divider>
                {metaConfig?.accomodations.map((element, index) => {
                    return (
                        <Form.Item
                            key={"accom" + element.id}
                            name={["accomodations", element.id]}
                            label={element.name}
                            tooltip={element.tooltip}
                        >
                            <Input key={"accomodations" + element.id} maxLength={50} />
                        </Form.Item>
                    )
                })}

                <Divider orientation="left">Override Reservation Rules</Divider>
                <Form.Item
                    hidden
                    name={["spaces", "reservationRules", "beginTime"]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    hidden
                    label="End Time"
                    name={["spaces", "reservationRules", "endTime"]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Timings" style={{ marginBottom: 14 }}>
                    <TimePicker placeholder="Begin time" showNow={false}
                        //defaultValue={beginTime}
                        value={beginTime}
                        format={'HH:mm'} allowClear={true} minuteStep={30}
                        style={{ marginRight: 20, marginBottom: 10, width: 229 }}
                        onSelect={(date: Dayjs) => {
                            setBegin(date);
                            form.setFieldValue(["spaces", "reservationRules", "beginTime"], (dayjs(date).format("HH:mm")));
                        }}
                    />
                    <TimePicker placeholder="End time" showNow={false}
                        value={endTime}
                        style={{ marginRight: 20, marginBottom: 10, width: 229 }}
                        format={'HH:mm'} allowClear={true} minuteStep={30}
                        onSelect={(date: Dayjs) => {
                            setEnd(date);
                            form.setFieldValue(["spaces", "reservationRules", "endTime"], (dayjs(date).format("HH:mm")));
                        }}
                    />
                </Form.Item>
                <Form.Item label="Duration" style={{ marginBottom: 14 }}>
                    <Form.Item
                        noStyle
                        label="Min Reservation Duration"
                        name={["spaces", "reservationRules", "minReservationDuration"]}
                        rules={[
                            {
                                type: "number",
                                min: 15,
                                max: 60
                            },
                        ]}
                    >
                        <InputNumber placeholder="15 min steps" min={15} max={60} step={15} precision={0} addonBefore="Min"
                            style={{ marginRight: 20, marginBottom: 10 }}
                        />
                    </Form.Item>

                    <Form.Item noStyle
                        name={["spaces", "reservationRules", "maxReservationDuration"]}
                        label="Max Reservation Duration"
                        rules={[
                            {
                                type: "number",
                                min: 60,
                                max: 300
                            }
                        ]}
                    >
                        <InputNumber placeholder="30 min steps" min={60} max={300} step={30} precision={0} addonBefore="Max"
                            style={{ marginRight: 20, marginBottom: 10 }}
                        />
                    </Form.Item>
                </Form.Item>

                <Form.Item label="Align Reservations" style={{ marginBottom: 14 }}>
                    <Form.Item noStyle
                        name={["spaces", "reservationRules", "alignReservations"]}
                        rules={[
                            {
                                type: "boolean"
                            }
                        ]}
                    >
                        <Radio.Group
                            optionType="button"
                            buttonStyle="solid"
                            name="alignReservations"
                            onChange={(e) => {
                                setAlign(e.target.value);
                            }}
                            style={{ marginRight: 20, marginBottom: 10 }}
                        >
                            <Radio key="ar-01" value={true}>Yes</Radio>
                            <Radio key="ar-02" value={false}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {align === true &&
                        <Form.Item
                            noStyle
                            label="Boundary"
                            name={["spaces", "reservationRules", "alignmentBoundary"]}
                            rules={[
                                {
                                    required: true,
                                    type: "number",
                                    min: 0,
                                    max: 45,
                                }
                            ]}
                        >
                            <InputNumber style={{ marginRight: 20, marginBottom: 10 }}
                                placeholder="Minutes from 0-45" addonBefore="Boundary"
                                precision={0} min={0} max={45} step={15} />
                        </Form.Item>
                    }
                </Form.Item>

                <Form.Item
                    label="Advance Days"
                    name={["spaces", "reservationRules", "advanceDays"]}
                    rules={[{
                        type: 'number',
                        min: 1,
                        max: 30
                    }]}
                >
                    <InputNumber min={1} max={30} step={1} precision={0} addonAfter="Days" />
                </Form.Item>
            </Form>
        </Edit>
    )
}